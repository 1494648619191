import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import FaqBanner from "../sections/faq/faq-banner"
import { graphql } from "gatsby"

import { useIntl } from "gatsby-plugin-react-intl"

const FaqPage = ({ data }) => {
  const intl = useIntl()
  return (
    <Layout footerCms={data.strapiFooter}>
      <SEO
        lang={intl.locale}
        title={data.strapiFaq.seo.title}
        description={data.strapiFaq.seo.description}
        keywords={data.strapiFaq.seo.keywords}
      />
      <FaqBanner
        generalQueries={data.allStrapiGeneralFaq.edges}
        technicalQueries={data.allStrapiTechnologyFaq.edges}
        cms={data.strapiFaq}
      ></FaqBanner>
    </Layout>
  )
}

export const query = graphql`
  query Faq($language: String) {
    strapiFaq(locale: { eq: $language }) {
      seo {
        title
        description
        keywords {
          tag
        }
      }
      title
      description
    }
    strapiFooter(locale: { eq: $language }) {
      company_name
      company_address
      call_center
      contact
      data_administrator
      rights_reserved
      page_links {
        title
        link
      }
      document_links {
        title
        link
      }
    }
    allStrapiTechnologyFaq(filter: { locale: { eq: $language } }) {
      edges {
        node {
          question
          answer
        }
      }
    }
    allStrapiGeneralFaq(filter: { locale: { eq: $language } }) {
      edges {
        node {
          question
          answer
        }
      }
    }
  }
`

export default FaqPage
